import React from "react"
import NextEventCard from "./nextEventCard"
import Button from "../atoms/button"

import styles from "./hero.module.scss"
import OutlineShape from "../atoms/outlineShape"
import "../atoms/outlineShape.scss"

class Hero extends React.Component {
  setLedeMarkup() {
    return {
      __html: this.props.lede,
    }
  }

  render() {
    const event = this.props.event

    return (
      <div
        className={event !== null ? `${styles.hero}` : `${styles.heroNoEvent}`}
      >
        <div className="wrapper wrapper--narrow">
          <OutlineShape hex="#006cd3" mod="outlineShape--blue" />
          <OutlineShape hex="#E02177" mod="outlineShape--pink" />
          <OutlineShape hex="#00bc64" mod="outlineShape--green" />

          <div className="grid">
            <div
              className={
                event !== null
                  ? "grid-child grid--push-start-1 grid-child--6-col-l grid-child--6-col-s"
                  : "grid-child grid--push-start-4 grid-child--6-col-l grid-child--6-col-s"
              }
              style={{ zIndex: "2" }}
            >
              <div
                className={`${
                  event !== null
                    ? styles.heroIntroWrap
                    : styles.heroIntroWrapNoEvent
                } `}
              >
                <h1 className="heading h2">{this.props.heading}</h1>
                <p
                  dangerouslySetInnerHTML={this.setLedeMarkup()}
                  className={
                    event !== null
                      ? `${styles.blurb} heading--normal heading h5`
                      : `${styles.blurbNoEvent} heading--normal heading h5`
                  }
                />

                <div className={styles.heroBtn}>
                  <Button
                    text="Join mailing list"
                    clickEvent={this.props.btnClickEvent}
                  />
                </div>
              </div>
            </div>

            {event !== null ? (
              <div
                className="grid-child grid--push-start-8 grid-child--5-col-l grid-child--6-col-s"
                style={{ zIndex: "2" }}
              >
                <NextEventCard event={event} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    )
  }
}

// Hero.propTypes = {
//     heading: PropTypes.string.isRequired,
//     lede: PropTypes.string.isRequired,
// }

export default Hero
