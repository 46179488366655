import { Link } from "gatsby"
import React from "react"

import SocialIcons from "../molecules/socialIcons"

import styles from "./header.module.scss"

import Logo from "../../images/logo-white.svg"

const Header = () => (
  <header className={styles.header}>
    <div className="wrapper">
      <div className={styles.headerWrapper}>
        <Link to="/">
          <img
            src={Logo}
            style={{ height: "50px", marginBottom: "0" }}
            alt="fiftyone3 logo"
          />
        </Link>
        <SocialIcons location="header" />
      </div>
    </div>
  </header>
)

export default Header
