import React from "react"
import PropTypes from "prop-types"

import styles from "./nextEventCard.module.scss"

import LocationLink from "../atoms/locationLink"
import ButtonLink from "../atoms/buttonLink"

import EventPlaceholder from "../../images/event-placeholder.jpg"

const currency = new Intl.NumberFormat("en-GB", {
	style: "currency",
	currency: "GBP",
	minimumFractionDigits: 0,
})

const NextEventCard = ({ event }) => ( 
	<div>
		<h3
			className="heading h5"
			style={{ textAlign: "center", paddingBottom: "1em" }}
		>
			Our next event
		</h3>
		<div className={styles.nextEventCard}>
			<div className={styles.nextEventCardImageWrap}>
				<img
					src={event.logoUrl === null ? EventPlaceholder : event.logoUrl}
					alt="event"
				/>
			</div>
			<span className={styles.nextEventCardPriceWrap}>
				<div className={styles.nextEventCardPricePill}>
					{currency.format(parseFloat(event.fee ? event.fee : null) + parseFloat(event.price)) + " per ticket"}
				</div>
			</span>
			<div className={styles.nextEventCardTextWrap}>
				<p className="heading heading--bold heading--center h3">
					{event.title}
				</p>
				<p className="heading heading--normal heading--center h5">
					{event.start}
				</p>
				<LocationLink link={event.link} text={event.venue} />
				<ButtonLink text="Get Tickets" link={event.link} />
			</div>
		</div>
	</div>
)

NextEventCard.propTypes = {
	event: PropTypes.object.isRequired,
}

export default NextEventCard
