import React from "react"
import PropTypes from "prop-types"

import styles from "./button.module.scss"

const Button = ({ text, hex, color, type, value, name, id, width, clickEvent }) => (
  <button
    className={`${styles.button} heading`}
    style={{ backgroundColor: hex, color: color, width: width }}
    id={id}
    name={name}
    value={value}
    type={type ? type : 'button'}
    onClick={clickEvent}
  >
    {text}
  </button>
)

Button.propTypes = {
  text: PropTypes.string.isRequired,
  hex: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  width: PropTypes.string,
}

export default Button
