import React from "react"
import PropTypes from "prop-types"

import styles from "./button.module.scss"
import InstagramIcon from "../../images/instagram-solo.svg"

const ButtonLink = ({ text, link, instagram }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className={`${styles.button}`}
  >
    {instagram ? (
      <img
        src={InstagramIcon}
        alt="insta icon"
        style={{ paddingRight: "0.5em" }}
      />
    ) : (
      ""
    )}
    {text}
  </a>
)

ButtonLink.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  instagram: PropTypes.bool,
}

export default ButtonLink
