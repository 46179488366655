import React from "react"
import PropTypes from "prop-types"

import "./socialIcons.scss"

const SocialIcons = ({ location }) => (
  <div className={"SocialIcons SocialIcons--" + location}>
    <a
      href="https://en-gb.facebook.com/fiftyone3/"
      target="_blank"
      rel="noopener noreferrer"
      className={
        location === "footer" ? "socialIcon socialIcon--footer" : "socialIcon"
      }
    >
      <svg id={`facebook-${location}`} viewBox="0 0 37 40">
        <path
          className="Fill-1"
          d="M3.8,8.55A5.47,5.47,0,0,0,1,13.29V26.71a5.47,5.47,0,0,0,2.8,4.74l11.9,6.71a5.7,5.7,0,0,0,5.6,0l11.9-6.71A5.47,5.47,0,0,0,36,26.71V13.29a5.47,5.47,0,0,0-2.8-4.74L21.3,1.84a5.73,5.73,0,0,0-5.6,0Z"
          fill="none"
          stroke={location === "footer" ? "#14191d" : "#ffffff"}
        />
        <path
          className="social-svg"
          d="M21.74,13.54H20.06A3,3,0,0,0,17,16.72v1.46H15.26a.27.27,0,0,0-.26.27v2.12a.27.27,0,0,0,.26.27H17V26.2a.26.26,0,0,0,.27.26h2.2a.26.26,0,0,0,.26-.26V20.84h2a.27.27,0,0,0,.26-.27V18.45a.27.27,0,0,0-.08-.19.25.25,0,0,0-.18-.08h-2V16.94c0-.6.15-.9.93-.9h1.13a.26.26,0,0,0,.26-.26v-2A.26.26,0,0,0,21.74,13.54Z"
          fill={location === "footer" ? "#14191d" : "#ffffff"}
        />
      </svg>
      <span className="visuallyhidden">facebook</span>
    </a>
    <a
      href="https://twitter.com/fiftyone3?lang=en"
      target="_blank"
      rel="noopener noreferrer"
      className={
        location === "footer" ? "socialIcon socialIcon--footer" : "socialIcon"
      }
    >
      <svg id={`twitter-${location}`} viewBox="0 0 37 40">
        <path
          className="Fill-1"
          d="M3.8,8.55A5.47,5.47,0,0,0,1,13.29V26.71a5.47,5.47,0,0,0,2.8,4.74l11.9,6.71a5.7,5.7,0,0,0,5.6,0l11.9-6.71A5.47,5.47,0,0,0,36,26.71V13.29a5.47,5.47,0,0,0-2.8-4.74L21.3,1.84a5.73,5.73,0,0,0-5.6,0Z"
          fill="none"
          stroke={location === "footer" ? "#14191d" : "#ffffff"}
        />
        <path
          className="social-svg"
          d="M25,15.83a5.6,5.6,0,0,1-1.53.43,2.74,2.74,0,0,0,1.17-1.53A5.15,5.15,0,0,1,23,15.4,2.65,2.65,0,0,0,21,14.53a2.72,2.72,0,0,0-2.67,2.76,3.2,3.2,0,0,0,.07.63A7.46,7.46,0,0,1,12.91,15a2.83,2.83,0,0,0,.82,3.68,2.61,2.61,0,0,1-1.21-.34v0a2.74,2.74,0,0,0,2.14,2.71,2.69,2.69,0,0,1-.7.1,2.07,2.07,0,0,1-.5-.06A2.67,2.67,0,0,0,16,23.08a5.23,5.23,0,0,1-3.31,1.18c-.22,0-.43,0-.64,0a7.39,7.39,0,0,0,4.09,1.24,7.69,7.69,0,0,0,7.59-7.86v-.36A5.46,5.46,0,0,0,25,15.83Z"
          fill={location === "footer" ? "#14191d" : "#ffffff"}
        />
      </svg>
      <span className="visuallyhidden">twitter</span>
    </a>
    <a
      href="https://www.linkedin.com/company/fiftyone3"
      target="_blank"
      rel="noopener noreferrer"
      className={
        location === "footer" ? "socialIcon socialIcon--footer" : "socialIcon"
      }
    >
      <svg id={`linkedin-${location}`} viewBox="0 0 37 40">
        <path
          className="Fill-1"
          d="M3.8,8.55A5.47,5.47,0,0,0,1,13.29V26.71a5.47,5.47,0,0,0,2.8,4.74l11.9,6.71a5.7,5.7,0,0,0,5.6,0l11.9-6.71A5.47,5.47,0,0,0,36,26.71V13.29a5.47,5.47,0,0,0-2.8-4.74L21.3,1.84a5.73,5.73,0,0,0-5.6,0Z"
          fill="none"
          stroke={location === "footer" ? "#14191d" : "#ffffff"}
        />
        <path
          className="social-svg"
          d="M24.76,21.35V26H22.08V21.66c0-1.08-.39-1.82-1.36-1.82a1.48,1.48,0,0,0-1.38,1,1.82,1.82,0,0,0-.09.65V26H16.57s0-7.32,0-8.08h2.68v1.14l0,0h0v0a2.68,2.68,0,0,1,2.42-1.33C23.44,17.72,24.76,18.87,24.76,21.35ZM13.76,14a1.41,1.41,0,0,0-1.52,1.4,1.39,1.39,0,0,0,1.48,1.4h0a1.4,1.4,0,1,0,0-2.8ZM12.4,26h2.68V17.91H12.4Z"
          fill={location === "footer" ? "#14191d" : "#ffffff"}
        />
      </svg>
      <span className="visuallyhidden">linkedin</span>
    </a>
    <a
      href="https://www.instagram.com/fiftyone3/"
      target="_blank"
      rel="noopener noreferrer"
      className={
        location === "footer" ? "socialIcon socialIcon--footer" : "socialIcon"
      }
    >
      <svg id={`instagram-${location}`} viewBox="0 0 37 40">
        <path
          className="Fill-1"
          d="M3.8,8.55A5.47,5.47,0,0,0,1,13.29V26.71a5.47,5.47,0,0,0,2.8,4.74l11.9,6.71a5.7,5.7,0,0,0,5.6,0l11.9-6.71A5.47,5.47,0,0,0,36,26.71V13.29a5.47,5.47,0,0,0-2.8-4.74L21.3,1.84a5.73,5.73,0,0,0-5.6,0Z"
          fill="none"
          stroke={location === "footer" ? "#14191d" : "#ffffff"}
        />
        <path
          className="social-svg"
          d="M21.35,13a4.18,4.18,0,0,1,4.19,4.17v5.66A4.18,4.18,0,0,1,21.35,27h-5.7a4.18,4.18,0,0,1-4.19-4.17V17.17A4.18,4.18,0,0,1,15.65,13Zm2.77,9.83V17.17a2.77,2.77,0,0,0-2.77-2.76h-5.7a2.77,2.77,0,0,0-2.77,2.76v5.66a2.77,2.77,0,0,0,2.77,2.76h5.7A2.77,2.77,0,0,0,24.12,22.83ZM18.5,16.75A3.25,3.25,0,1,1,15.23,20,3.27,3.27,0,0,1,18.5,16.75Zm0,5.24a2,2,0,1,0-2-2A2,2,0,0,0,18.5,22Zm3.59-4.74a.63.63,0,1,1,.63-.63A.63.63,0,0,1,22.09,17.25Z"
          fill={location === "footer" ? "#14191d" : "#ffffff"}
        />
      </svg>
      <span className="visuallyhidden">instagram</span>
    </a>
  </div>
)

SocialIcons.propTypes = {
  location: PropTypes.string.isRequired,
}

export default SocialIcons
