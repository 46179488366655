import { Link } from "gatsby"
import React from "react"

import Logo from "../../images/logo-pink.svg"

import styles from "./footer.module.scss"

import SocialIcons from "../molecules/socialIcons"

const Footer = () => (
  <footer className={styles.footer}>
    <div className="wrapper">
      <div className={`grid ${styles.gridAreaFooter} `}>
        <div
          className={`grid-child grid--push-start-1 grid-child--2-col-l grid-child--6-col-s ${
            styles.logoGridSmall
          } `}
        >
          <div className={styles.footerLogoWrap}>
            <Link to="/">
              <img src={Logo} alt="fiftyone3" className={styles.footerLogo} />
            </Link>
          </div>
        </div>
        <div
          className={`grid-child grid--push-start-3 grid-child--7-col-l grid-child--6-col-s ${
            styles.legalGridSmall
          } `}
        >
          <div className={`${styles.footerLegal} body`}>
            <p>© {new Date().getFullYear()} Fiftyone3</p>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <a
              href="https://bluegg.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Website by Bluegg
            </a>
          </div>
        </div>
        <div
          className={`grid-child grid--push-start-10 grid-child--3-col-l grid-child--6-col-s ${
            styles.socialGridSmall
          } `}
        >
          <SocialIcons location="footer" />
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
