import React from "react"
import PropTypes from "prop-types"

const OutlineShape = ({ hex, mod }) => (
  <>
    <svg
      width="883px"
      height="939px"
      viewBox="0 0 883 939"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        zIndex: "1",
      }}
      className={`${mod} outlineShape`}
    >
      <g
        id={`outline-shape-${hex}`}
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-279.000000, -2656.000000)"
          stroke={hex}
          strokeWidth="10"
        >
          <path
            d="M367.686559,2840.26331 C326.118274,2864.61597 300.516718,2909.63188 300.526355,2958.3541 L300.592441,3292.47875 C300.602078,3341.20097 326.221443,3386.22728 367.799365,3410.59683 L652.931931,3577.71798 C694.510458,3602.08692 745.730774,3602.09732 787.29906,3577.74526 L1072.36554,3410.73992 C1113.93443,3386.38725 1139.53599,3341.37134 1139.52635,3292.64912 L1139.46026,2958.52447 C1139.45063,2909.80225 1113.83126,2864.77594 1072.25273,2840.40639 L787.120167,2673.28648 C766.331509,2661.1017 743.13195,2655.00671 719.932989,2655.002 C696.734635,2654.99729 673.537484,2661.08285 652.753039,2673.25919 L367.686559,2840.26331 Z"
            transform="translate(720.026352, 3125.501996) rotate(55.000000) translate(-720.026352, -3125.501996) "
          />
        </g>
      </g>
    </svg>
  </>
)

OutlineShape.propTypes = {
  hex: PropTypes.string.isRequired,
  mod: PropTypes.string.isRequired,
}

export default OutlineShape
