import React from "react"
import PropTypes from "prop-types"

import Location from "../../images/location.svg"
import styles from "../atoms/locationLink.module.scss"

const LocationLink = ({ text, link }) => (
  <a
    href={link}
    className={`${styles.locationLink} heading`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={Location} alt="location pin" />

    {text}
  </a>
)

LocationLink.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default LocationLink
